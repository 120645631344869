import React, { useState, useEffect, PropTypes } from "react"
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import PeopleDetail from "../components/StaticComponent/Founder/Founder";
import AreaGuideStaticContact from "../components/Home/ContactUs/AreaGuideStaticContact";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/team-details-template.scss"


const TeamTemplate = (props) => {
  const [state, setState] = React.useState({
    showMenu: false
  })
  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }

  const { URL } = props.pageContext || {};
  const capitalizedURL = URL ? URL.charAt(0).toUpperCase() + URL.slice(1) : '';

  useEffect(() => {

  }, [])

  let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
  return (
    <div className={`team-landing-page page-team-details-wrap`}>
      
      <SEO title={'Real Estate Experts - Dacha'} description={'Find out more details about our team of real estate experts, making Dacha the local experts'} location={props.location}/>
      <div className={`${state.showMenu ? "open-search-block" : ""}`}>
        <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
        <Breadcrumb data={{
            Label: "Our Team",
            Main_Parent: { Label: 'About Us', id: '609a84570336654c2f3c0289', URL: 'about-dacha' },
            Sub_Parent: null
          }} page={'Our People'} tag="detail-page" category={`Our People`} name={capitalizedURL} />
        <div className={language === 'Russian' ? "russian-page-modules page-modules" : "page-modules"}>
          <PeopleDetail url={props.pageContext.URL} location={props.location} />
          <AreaGuideStaticContact />
          {/* <Breadcrumb data={{
            Label: "Our Team",
            Main_Parent: { Label: 'About Us', id: '609a84570336654c2f3c0289', URL: 'about-dacha' },
            Sub_Parent: null
          }} page={'Our People'} tag="detail-page" category={`Our People`} name={props.pageContext.URL} /> */}
        </div>
        <NewsLetter />
        <Footer popularSearch={`Popular_Search_About`} />
      </div>
    </div>
  )
}

export default TeamTemplate
